// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-404-js": () => import("./../src/templates/404.js" /* webpackChunkName: "component---src-templates-404-js" */),
  "component---src-templates-500-js": () => import("./../src/templates/500.js" /* webpackChunkName: "component---src-templates-500-js" */),
  "component---src-templates-blog-js": () => import("./../src/templates/blog.js" /* webpackChunkName: "component---src-templates-blog-js" */),
  "component---src-templates-booking-js": () => import("./../src/templates/booking.js" /* webpackChunkName: "component---src-templates-booking-js" */),
  "component---src-templates-contact-js": () => import("./../src/templates/contact.js" /* webpackChunkName: "component---src-templates-contact-js" */),
  "component---src-templates-home-js": () => import("./../src/templates/home.js" /* webpackChunkName: "component---src-templates-home-js" */),
  "component---src-templates-post-js": () => import("./../src/templates/post.js" /* webpackChunkName: "component---src-templates-post-js" */),
  "component---src-templates-pricing-js": () => import("./../src/templates/pricing.js" /* webpackChunkName: "component---src-templates-pricing-js" */),
  "component---src-templates-success-js": () => import("./../src/templates/success.js" /* webpackChunkName: "component---src-templates-success-js" */),
  "component---src-templates-vouchers-js": () => import("./../src/templates/vouchers.js" /* webpackChunkName: "component---src-templates-vouchers-js" */)
}

